@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Parkinsans:wght@300..800&display=swap');
body {
  margin: 0;
  font-family: 'Parkinsans', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  padding: 1rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.hero-navbar {
  background: transparent !important;
  box-shadow: none !important;
}

.logo {
  max-width: 200px;
}

.css-1588512-MuiButtonBase-root-MuiButton-root, .MuiTypography-root h1, h2, h3,h4, h5, h6, p{
  font-family: 'Parkinsans', sans-serif !important;
}

.nav-link_color {
  font-family: 'Parkinsans', sans-serif !important;
  color: black;
  font-weight: 500 !important;
  font-size: 16px !important;
}

.nav-link_color:hover {
  background-color: #7832F3;
  color: #fff;

}

.burger-icon {
  background-color: black !important;
  color: white;
}

.text-top {
  padding-top: 24rem;
}

.card-hover {
  color : black;
}

.card-hover p {
    max-width: 230px;
    text-align: right;
    line-height: 17px;
}

.card-hover h2 {
  max-width: 200px;
}

.card-hover:hover {
  color: white !important;
  background-color: #7832f3 !important;
}

.card-hover:hover > .hover-content {
  color: white;
}

.card-hover-about:hover{
  color: white !important;
  background-color: #7832f3 !important;
}

.contact-arrow {
  font-size: 9rem !important;
}

.custom-form .MuiInputBase-root  {
  border-radius: 2rem !important;
  box-shadow: 0 0 0 4px #f5f5f5 !important;
}

.custom-button {
  background-color: #7832f3 !important;
    border-radius: 30px !important;
    max-width: 120px;
    padding: 10px;
    margin: auto !important;
    display: flex !important;
    justify-content: center;
    text-transform: capitalize;
}

.custom-button:hover {
  background-color: #5814cd !important;
}

--AppBar-color{
  color: #000;
}